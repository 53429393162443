import React from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import NotFoundAnim from "../assets/anims/404.json";

const PageNotFound = () => {
  return (
    <div className="page-not-found-parent font22">
      <Player
        autoplay
        loop
        src={NotFoundAnim}
        style={{ height: "500px", width: "500px" }}
      ></Player>
    </div>
  );
};

export default PageNotFound;
