import React from "react";
import { useHistory } from "react-router-dom";
import Dropdown from "rsuite/Dropdown";
import "rsuite/dist/rsuite.min.css";
import { set } from "lodash";

const MultilevelDropdown = (props) => {
  let history = useHistory();
  const gotoScreen = (screenName) => {
    history.push(screenName);
  };
  const menu = [
    // { title: "Home", screen: "/" },
    {
      title: "About us",
      screen: "/about-us",
    },

    {
      title: "Activities",
      screen: "/activities",
    },
    {
      title: "Branches",
      screen: "/branches",
    },
    {
      title: "Gallery",
      screen: "/gallery",
    },
    {
      title: "Donate",
      screen: "/donate",
    },
    {
      title: "Our Team",
      screen: "/team",
    },
    {
      title: "Contact us",
      screen: "/contact-us",
    },
  ];

  return (
    <div className="multi-level-dropdown-container">
      {menu.map((mainMenuItem, key) => {
        return mainMenuItem.subMenu ? (
          <Dropdown
            title={mainMenuItem.title}
            trigger="hover"
            placement="bottomStart"
            style={{ marginRight: 6 }}
            key={key}
          >
            {mainMenuItem &&
              mainMenuItem.subMenu &&
              mainMenuItem.subMenu.map((subMenuItem) => {
                return subMenuItem && subMenuItem.subMenu ? (
                  <Dropdown
                    title={subMenuItem.name}
                    trigger="hover"
                    placement="rightStart"
                    style={{ marginRight: 10 }}
                  >
                    {subMenuItem.subMenu.map((innerSubItem) => {
                      return (
                        <Dropdown.Item
                          key={innerSubItem.id}
                          onClick={() => gotoScreen(innerSubItem.screen)}
                          className="font11"
                        >
                          {innerSubItem.name}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown>
                ) : (
                  <Dropdown.Item
                    key={subMenuItem.id}
                    onClick={() => gotoScreen(subMenuItem.screen)}
                    className="font11"
                  >
                    {subMenuItem.name}
                  </Dropdown.Item>
                );
              })}
          </Dropdown>
        ) : (
          <div
            className="rs-dropdown"
            onClick={() =>
              mainMenuItem.screen ? gotoScreen(mainMenuItem.screen) : null
            }
            key={key}
          >
            <button className="rs-btn">{mainMenuItem.title}</button>{" "}
          </div>
        );
      })}
    </div>
  );
};

export default MultilevelDropdown;
