import React, { useEffect, useState } from "react";
import AppData from "../../AppData";
import HeroBanner from "../../components/HeroBanner";
import SingularTabs from "../../components/SingularTabs";
import { FaCalendar } from "react-icons/fa";
import { callApi } from "../../api/callApi";
import MyModal from "../../components/MyModal";

const Default = () => {
  const [tabs, setTabs] = useState([
    { id: 0, name: "Our Goals", active: true },
    { id: 1, name: "Our Works", active: false },
    // { id: 2, name: "Workshops", active: false },
  ]);

  const [workshops, setWorkshops] = useState([]);
  const [goals, setGoals] = useState([]);
  const [works, setWorks] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    let workshops = [];
    let goals = [];
    let works = [];
    callApi({
      url: AppData.BASE_URL + "api/v1/cms/activities",
      method: "GET",
      callback: (result) => {
        if (
          result &&
          result.data &&
          result.data.data &&
          result.data.data.length > 0
        ) {
          // setTabsData(result.data.data);

          result.data.data.map((val) => {
            switch (val.section_title) {
              case "Workshops":
                workshops.push(val);
                break;
              case "Goals":
                goals.push(val);
                break;
              case "Our Works":
                works.push(val);
                break;
              default:
                break;
            }
          });

          setWorkshops(workshops);
          setGoals(goals);
          setWorks(works);
        }
      },
    });
  }, []);

  const renderActivityItem = (activity, key, showDate = true) => {
    return (
      <div className="activity-item" key={key}>
        <div className="img-container">
          <img
            src={AppData.BASE_URL + "upload/cms/" + activity.attachment}
            alt="icon"
          />
        </div>
        {showDate && (
          <div className="date-time">
            <FaCalendar fontSize={18} />
            <span className="date">{activity.updated_at.split("T")[0]}</span>
          </div>
        )}
        <div className="title bold-text">{activity.title}</div>
        <div
          className="content description-text"
          dangerouslySetInnerHTML={{
            __html: activity?.description?.slice(0, 500),
          }}
        ></div>
        <div
          className="read-more-activities"
          onClick={() => {
            setShowModal(true);
            setCurrentItem(activity);
          }}
        >
          <span>Read More</span>
        </div>
      </div>
    );
  };

  return (
    <div>
      <HeroBanner name="What We Do? Our Works and Goals!" />

      <div className="rest-page content-wrapper">
        <SingularTabs tabs={tabs} setTabsState={setTabs} />

        <div>
          {tabs[0].active ? (
            <div className="activities-container">

              {works &&
                works.map((visit, key) => {
                  return renderActivityItem(visit, key);
                })}

            </div>
          ) : null}

          {tabs[1].active ? (
            <div className="activities-container">
              {goals &&
                goals.map((goal, key) => {
                  return renderActivityItem(goal, key, false);
                })}
            </div>
          ) : null}

          {/* {tabs[2].active ? (
            <div className="activities-container">
              {workshops &&
                workshops.map((workshop, key) => {
                  return renderActivityItem(workshop, key);
                })}

            </div>
          ) : null} */}
        </div>
      </div>

      <MyModal
        open={showModal}
        title={currentItem && currentItem.title}
        desc={currentItem && currentItem.description}
        onClose={() => setShowModal(false)}
      />
    </div>
  );
};

export default Default;
