import React, { useEffect, useState } from "react";
import { callApi } from "../../api/callApi";
import AppData from "../../AppData";

const Default = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [values, setValues] = useState({});

  const changeText = (val, type) => {
    let vals = { ...values };
    vals[type] = val;

    setValues(vals);
  };

  const submitForm = (e) => {
    e.preventDefault();
    callApi({
      url: AppData.BASE_URL + "api/v1/feedback",
      method: "POST",
      data: values,
      callback: (res) => {
        if (res && res.data && res.data.success) {
          setValues({});
          alert("Form submitted");
          window.location.reload();
        }
      },
    });
  };

  return (
    <div className="feedback-parent">
      <div className="feedback-container card">
        <form className="feedback-form" onSubmit={submitForm}>
          <span className="feedback-title">Send us your Feedback</span>
          <label>Name:</label>
          <input
            className="feedback-input"
            type="text"
            placeholder="  Write Your Name Here"
            onChange={(val) => changeText(val.target.value, "name")}
            value={values ? values.name : ""}
          ></input>
          <label>Email:</label>
          <input
            className="feedback-input"
            type="email"
            placeholder="  Lets know how to contact you"
            onChange={(val) => changeText(val.target.value, "email")}
            value={values ? values.email : ""}
          ></input>
          <label>Message:</label>
          <textarea
            className="feedback-input feedback-message"
            type="text"
            placeholder="  What would you like to tell us"
            onChange={(val) => changeText(val.target.value, "message")}
            value={values ? values.message : ""}
          ></textarea>
          <div className="btn-class">
            <button className="feedback-button" type="submit">
              {" "}
              Send
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Default;
