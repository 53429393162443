import React, { useEffect, useState } from "react";
// import { useHistory } from "react-router-dom";
import { atom, selector, useRecoilState, useRecoilValue } from "recoil";
import { callApi } from "../../api/callApi";
import AppData from "../../AppData";
import HeroBanner from "../../components/HeroBanner";

const Branches = () => {
  const [branches, setBranches] = useState([]);

  useEffect(() => {
    loadBranches();
    window.scrollTo(0, 0);
  }, []);

  const loadBranches = () => {
    callApi({
      url: AppData.BASE_URL + "api/v1/branches",
      method: "GET",
      callback: (result) => {
        if (result?.data?.data?.length > 0) {
          setBranches(result.data.data);
        }
      },
    });
  };

  return (
    <div className="aboutskuast-k-container">
      <HeroBanner name="Branches - Darul Atta" />
      <div className="rest-page content-wrapper" style={{ minHeight: "30vh" }}>
        <div className="about-container" style={{ marginTop: 42 }}>
          {/* <div className="">{getDataToDisplay("About us")}</div> */}

          {branches?.map((tab, index) => {
            return (
              <div className="branch_wrapper">
                <div className="title_and_number">
                  <span className="number">{index + 1}</span>
                  <div
                    className="location"
                    dangerouslySetInnerHTML={{ __html: tab.location }}
                  />
                </div>
                <div
                  className="facilities"
                  dangerouslySetInnerHTML={{ __html: tab.facilities }}
                />
                <div
                  className="contact_info"
                  dangerouslySetInnerHTML={{ __html: tab.conatct_info }}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default Branches;
