import React, { useEffect, useState } from "react";
import { callApi } from "../../api/callApi";
import AppData from "../../AppData";
// import { atom, selector, useRecoilState, useRecoilValue } from "recoil";
import HeroBanner from "../../components/HeroBanner";
// import TabsComponent from "../../components/TabsComponent";
// import { publicationsTabsState } from "../../Recoil/atoms";

const Default = () => {
  const [members, setMembers] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    callApi({
      url: AppData.BASE_URL + "api/v1/team",
      method: "GET",
      callback: (result) => {
        if (
          result &&
          result.data &&
          result.data.data &&
          result.data.data.length > 0
        ) {
          setMembers(result.data.data);
        }
      },
    });
  }, []);

  const twitterIcon =
    "https://cdn4.iconfinder.com/data/icons/social-media-icons-the-circle-set/48/twitter_circle-512.png";

  const openLink = (link) => {
    window.open(link, "_blank");
  };

  const singleMember = (member) => {
    return (
      <div id={member.id} className="single-profile-card card">
        <div>
          <img
            className="profile-photo"
            src={AppData.BASE_URL + "upload/cms/" + member.image}
          />
        </div>
        <div className="name">
          {" "}
          <h5 className="primary-color">{member.name}</h5>
        </div>
        <div className="desg-container">
          {" "}
          <span className="designation">{member?.designation}</span>{" "}
        </div>
        <div>
          <span
            style={{
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {member.bio.slice(0, 200)}
          </span>
        </div>
        <div onClick={() => openLink(member.twitter)}>
          <img className="profile-icon" src={twitterIcon}></img>
        </div>
      </div>
    );
  };
  return (
    <div>
      <HeroBanner name="Darul Atta - Team" />
      <div
        className="profile-card-container content-wrapper"
        style={{ minHeight: "30vh" }}
      >
        <div className="profile-cards">
          <div className="profile-grid">
            {members &&
              members.map((member) => {
                return singleMember(member);
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Default;
