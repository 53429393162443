import React from "react";

const HeroBanner = (props) => {
  return (
    <div className="about-skuast-img-container">
      <div className="about-banner-img">
        <img src={props.image ? props.image : "/images/banner.jpg"} />
      </div>
      <div className="about-banner-text font14 overlay">
        <span className="bold-text">{props.name}</span>
      </div>
    </div>
  );
};

export default HeroBanner;
