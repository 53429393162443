import React, { useEffect, useState } from "react";
// import { useHistory } from "react-router-dom";
import { atom, selector, useRecoilState, useRecoilValue } from "recoil";
import { callApi } from "../../api/callApi";
import AppData from "../../AppData";
import HeroBanner from "../../components/HeroBanner";

const Branches = () => {
  const [branches, setBranches] = useState([]);

  useEffect(() => {
    loadBranches();
    window.scrollTo(0, 0);
  }, []);

  const loadBranches = () => {
    callApi({
      url: AppData.BASE_URL + "api/v1/branches",
      method: "GET",
      callback: (result) => {
        if (result?.data?.data?.length > 0) {
          setBranches(result.data.data);
        }
      },
    });
  };


  const copy = () => {
    navigator.clipboard.writeText("Darulatta charitable society IFSC code JAKA0SKADAL sadka:0752010100000028 zakaat:0752010100000029 imdaad:0752010100000093");
    alert("Account details copied to clipboard")
  }

  return (
    <div className="aboutskuast-k-container">
      <HeroBanner name="Donate us - Darul Atta" />
      <div className="rest-page content-wrapper" style={{ minHeight: "30vh" }}>
        <div className="about-container payment_container" style={{ marginTop: 42 }}>

          <div className="donate font14 card">

            <div className="acc_entry ">
              NAME : <span>Darulatta Charitable Society</span>
            </div>
            <div className="acc_entry">
              IFSC CODE: <span>JAKA0SKADAL</span>
            </div>
            <div className="acc_entry">SADKA:<span>0752010100000028</span></div>
            <div className="acc_entry">ZAKAAT: <span>0752010100000029</span></div>
            <div className="acc_entry">IMDAAD: <span>0752010100000093</span></div>


            <img onClick={copy} className="copy" src="/images/copy.png" alt="copy" />
          </div>

          <div className="qr_container">
            <img onClick={copy} className="qr" src="/images/qr.png" alt="copy" />

          </div>

          {/* {branches?.map((tab, index) => {
            return (
              <div className="branch_wrapper">
                <div className="title_and_number">
                  <span className="number">{index + 1}</span>
                  <div
                    className="location"
                    dangerouslySetInnerHTML={{ __html: tab.location }}
                  />
                </div>
                <div
                  className="facilities"
                  dangerouslySetInnerHTML={{ __html: tab.facilities }}
                />
                <div
                  className="contact_info"
                  dangerouslySetInnerHTML={{ __html: tab.conatct_info }}
                />
              </div>
            );
          })} */}
        </div>
      </div>
    </div>
  );
};
export default Branches;
