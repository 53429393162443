import React, { useEffect, useState } from "react";
import { callApi } from "../../api/callApi";
import AppData from "../../AppData";
import HeroBanner from "../../components/HeroBanner";
import SingularTabs from "../../components/SingularTabs";

const Default = () => {
  const [contactUsTabs, setContactUsTabs] = useState([
    { id: 0, name: "Contact Us", active: true },
    { id: 1, name: "Map Address", active: false },
  ]);
  const [details, setDetails] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
    getContactDetails();
  }, []);

  const getContactDetails = () => {
    callApi({
      url: AppData.BASE_URL + "api/v1/contacts",
      method: "GET",
      callback: (result) => {
        if (result && result.data && result.data.data) {
          setDetails(result.data.data[0]);
        }
      },
    });
  };

  const contactDetails = () => {
    return (
      <div className="contact-us-container">
        <div className="upper-half">
          <div className="right-half">
            <div>
              <span id="address">
                Address: <span className="bold-text">{details?.address}</span>{" "}
              </span>
            </div>
            <div>
              <span id="phone-no">
                Phone: <span className="bold-text">{details?.mobile}</span>{" "}
              </span>
            </div>
            <div>
              <span id="email">
                Email: <span className="bold-text">{details?.email}</span>
              </span>
            </div>
            <div>
              <span id="whatsapp">
                Whatsapp: <span className="bold-text">{details?.whatsapp}</span>{" "}
              </span>
            </div>
          </div>
          <div className="left-half">
            <div>
              <span id="contact-us" className="bold-text">
                Contact Us
              </span>
            </div>
            <div>
              <span id="details" className="general-text">
                {details?.details}
              </span>
            </div>
          </div>
        </div>
        <div className="lower-half"> </div>
      </div>
    );
  };
  const geoLocation = () => {
    const location = `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3294.107427873407!2d${details?.longitude}!3d${details?.latitude}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38e1057d6763ff17%3A0xcd23a2d8361c329b!2sFaculty%20of%20Agriculture%20Wadura!5e0!3m2!1sen!2sin!4v1667383185742!5m2!1sen!2sin`;
    return (
      <div className="map-container">
        <div>
          <iframe
            className="google-map-image"
            src={location}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    );
  };

  return (
    <div>
      <HeroBanner name="Contact Us" />

      <div className="rest-page content-wrapper">
        <SingularTabs tabs={contactUsTabs} setTabsState={setContactUsTabs} />
        <div>
          {contactUsTabs[0].active ? contactDetails() : null}
          {contactUsTabs[1].active ? geoLocation() : null}
        </div>
      </div>
    </div>
  );
};

export default Default;
