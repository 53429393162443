import React from "react";
import MultilevelDropdown from "./MultilevelDropdown";
import { useHistory } from "react-router-dom";

function Header(props) {
  let history = useHistory();

  return (
    <div className="header-parent">
      <div className="logo-conntainer" onClick={() => history.push("/")}>
        <img src="/images/logo.png" alt="logo" />
        {/* <div
          className="font9 white-color website-title"
          style={{ marginTop: 2 }}
        >
          <span className="font13 bold-text">Darul Atta</span>
          <span className="font9 line2">A Chairtable Trust</span>
        </div> */}
      </div>
      <div className="links-container font15">
        <MultilevelDropdown />
      </div>
    </div>
  );
}

export default Header;
