import React, { useEffect, useState } from "react";
import { callApi } from "../../api/callApi";
import AppData from "../../AppData";
import HeroBanner from "../../components/HeroBanner";

const AboutSkuastK = () => {
  const [aboutTabsData, setAboutTabsData] = useState([]);

  useEffect(() => {
    loadAboutCMS();
    window.scrollTo(0, 0);
  }, []);

  const loadAboutCMS = () => {
    callApi({
      url: AppData.BASE_URL + "api/v1/cms/about",
      method: "GET",
      callback: (result) => {
        if (
          result &&
          result.data &&
          result.data.data &&
          result.data.data.length > 0
        ) {
          setAboutTabsData(result.data.data);
        }
      },
    });
  };

  const getDataToDisplay = (mySection) => {
    return (
      <div className="description-text">
        {aboutTabsData.map((tab) => {
          if (tab.section_title === mySection) {
            return (
              <div dangerouslySetInnerHTML={{ __html: tab.description }} />
            );
          }
        })}
      </div>
    );
  };

  return (
    <div className="aboutskuast-k-container">
      <HeroBanner name="About Darul Atta - A Chairtable Trust" />
      <div className="rest-page content-wrapper" style={{ minHeight: "30vh" }}>
        {/* <SingularTabs tabs={aboutTabs} setTabsState={setAboutTabs} /> */}

        <div className="about-container" style={{ marginTop: 42 }}>
          <div className="">{getDataToDisplay("About us")}</div>
        </div>
      </div>
    </div>
  );
};
export default AboutSkuastK;
